.tippy-popper {
  max-width: none;
}

.tippy-box {
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  padding-bottom: 0;
}

.tippy-content {
  padding: 0;
}

.tippy-box[data-theme~='socialie'] {
  @apply bg-white py-0.5 px-1 text-bodyText;
  border-radius: 4px;
  filter: drop-shadow(0px 8px 16px rgba(0, 0, 0, 0.16));
}

.tippy-box[data-theme~='socialieNoPad'] {
  @apply bg-white text-bodyText p-0;
  border-radius: 4px;
  filter: drop-shadow(0px 8px 16px rgba(0, 0, 0, 0.16));
}

.tippy-box[data-theme~='socialieDark'] {
  @apply bg-grey1 py-0.75 px-1 leading-16 text-white;
  border-radius: 4px;
  box-shadow: none;
  max-width: 300px;
  font-size: 13px;
  font-weight: bold;
  filter: drop-shadow(0px 8px 16px rgba(0, 0, 0, 0.16));
}

.tippy-box[data-theme~='FlyoutMenu'] {
  @apply min-w-18 max-h-32 text-bodyText bg-white p-1;
  border-radius: 8px;
  box-shadow: 0 24px 48px 0 rgba(0, 0, 0, 0.16);
  overflow-y: auto;
  text-align: left;
}

.tippy-box[data-theme~='AccountsMenu'] {
  @apply min-w-34 max-h-40 text-bodyText bg-white p-2;
  border-radius: 8px;
  box-shadow: 0 24px 48px 0 rgba(0, 0, 0, 0.16);
  overflow-y: auto;
  text-align: left;
}
